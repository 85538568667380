html {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

body {
  color: #ccc;
  background-color: #000;
}

.App {
  overflow: hidden;
}

.react-tabs__tab-list {
  border: none;
}

.react-tabs__tab:focus:after {
  display: none !important;
}

.react-tabs__tab {
  border: none !important;
  color: #cccccc4d;
  padding: 8px 0 !important;
  margin-right: 20px;
}

.react-tabs__tab--selected {
  background: none !important;
  color: #ccc;
  font-weight: bold;
  border-bottom: 4px solid #1d9bf0 !important;
}

@media screen and (max-width: 500px) {
  html {
    font-size: 16px;
  }
}
